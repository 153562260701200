import React, {useEffect,useState} from 'react';
import Home from './Components/Home';
import './Common/style.css'
import  GoogleMap  from './Components/googleMap';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  createBrowserRouter,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import LayoutPage from "./Common/LayoutPages";
import SundarkandBook from  "./Components/SundarkandBook"
import CalenderDemo from "./Components/CalenderDemo"

function App(props) {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 900,
      easing: 'ease-in-sine',
      delay: 150,
    });
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<LayoutPage />}>
            <Route path="/" element={<Home />} {...props}/>
            <Route path="SundarkandBook" element={<SundarkandBook />} {...props}/>
            <Route path="CalenderDemo" element={<CalenderDemo />} {...props}/>
            <Route path="GoogleMap" element={<GoogleMap />} {...props}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
