import Configuration from "../Shared/configuration";
import EndPointEntity from "../Shared/endpointentity";
import API from "../Shared/API";

export default class CompanyInfoServices {
    constructor() {
        this.config = new Configuration();
        this.endpoint = new EndPointEntity();
    }
    async GetRecords() {
        const myApi = new API({ url: this.config.API_URL });
        myApi.createEntity({ name: this.endpoint.GET_RECORDS });
        let itemArray = [];
        await myApi.endpoints[this.endpoint.GET_RECORDS].getAllWithoutToken()
            .then(({ data }) => {
                itemArray = data;
            })
            .catch((err) => {
                console.log(err);
            });
        return Promise.resolve(itemArray);
    }
}
